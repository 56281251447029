import store from '@/store'
import { downDom } from '@/utils'
import { getToken } from '@/utils/auth'
import axios, { Method } from 'axios'
import { ElNotification, ElMessageBox } from 'element-plus'


// 创建一个 axios 实例

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000, // 请求时间
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
})

// 请求拦截
service.interceptors.request.use(config => {
  // 在发送请求之前做的一些事情
  config.url = config.url?.includes('/mall-') ? config.url : '/mall-portal' + config.url
  if(store.getters.GetToken) {
    config.headers['Authorization'] = getToken()
  }
  return config
}, error => {
  // 请求错误
  return Promise.reject(error)
})

// 响应拦截
service.interceptors.response.use(response => {
  const res = response.data
  if (res.code !== 200) {
    if(res.code === 401){
      // store.dispatch('setLayoutFilter', true)
      // store.dispatch('loginShowChange', true)
    } else {
      ElNotification({
        title: '失败',
        type: 'error',
        message: res.message || '请求错误',
        duration: 5 * 1000,
      })
    }
    return Promise.reject(new Error(res.message || 'Error'))
  } else {
    return res
  }
}, error => {
    switchHttpStatus(error.response.status, () => {
      // 拦截错误做的事情
      ElNotification({
        title: '失败',
        type: 'error',
        message: error.message || '请求错误',
        duration: 5 * 1000,
      })
    })
    return Promise.reject(error)
  })

export default service

/**
 * @description 下载文件
 * @author Jinx
 * @date 2021-07-04 12:08:59
 * @export
 * @param {ExportFileAllData} { method, url, fileName, fileType, isDown, data, params, fileExtension }
 * @returns {*}  {Promise<Response>}
 */
export function exportFile({ method, url, fileName, fileType, isDown, data, params, fileExtension }: ExportFileAllData): Promise<Response> {
  return new Promise((resolve, reject) => {
    const obj: ExportFileData = { method, url }
    obj.method !== 'get' && obj.method !== 'GET' ? obj.data = data : obj.params = params
    obj.url = '/mall-admin' + obj.url
    axios({
      ...obj,
      headers: {
        Authorization: getToken()
      },
      responseType: 'blob'
    }).then(res => {
      const blob = new Blob([res.data], { type: fileType})
      const name = fileExtension ? `${fileName}.${fileExtension}` : fileName
      if(isDown) {
        downDom(name, blob)
      }
      resolve(res.data)
    }).catch(err => reject(err))
  })
}

/**
 * @description 错误信息提示
 * @author Jinx
 * @date 2021-08-04 12:08:21
 * @export
 * @param {number} status
 * @param {Cb} Options
 */
export function switchHttpStatus(status: number, Options: Cb ):void {
  switch(status) {
    case 403:
      // 不允许访问
      ElNotification({
        title: '失败',
        type: 'error',
        message: '没有权限，不允许访问',
        duration: 5 * 1000,
      })
      break
    case 401:
      // 身份过期
      ElMessageBox.confirm('身份已过期，请重新登录', '重新登录', {
        confirmButtonText: '重新登录',
        cancelButtonText: '退出',
        type: 'warning'
      }).then(() => {
        store.dispatch('resetToken').then(() => {
          location.reload()
        })
      })
      break
    case 502:
      // 网关错误
      ElMessageBox.confirm('网关错误，请检查服务器', '网关错误', {
        confirmButtonText: '重新登录',
        cancelButtonText: '退出',
        type: 'warning'
      }).then(() => {
        store.dispatch('resetToken').then(() => {
          location.reload()
        })
      })
      break
    default:
      Options()
  }
}

export declare type Cb = () => void

/**
 * @description 下载文件
 * @author Jinx
 * @date 2021-08-04 12:09:14
 * @export
 * @interface ExportFileData
 */
 export interface ExportFileData {
  method?: Method | undefined
  url: string
  data?: any
  params?: any
}

/**
 * @description 下载文件
 * @author Jinx
 * @date 2021-08-04 12:09:00
 * @export
 * @interface ExportFileAllData
 * @extends {ExportFileData}
 */
export interface ExportFileAllData extends ExportFileData {
  fileName ?: string
  fileType: string
  fileExtension?: string
  isDown?: boolean
}

/**
 * @description 下载文件返回
 * @author Jinx
 * @date 2021-08-04 12:09:30
 * @export
 * @interface Response
 */
export interface Response {
  code?: number
  data?: any
  message?: string
  [propName: string]: any
}


export interface ResponseData {
  createTime: Date | string
  updateTime: Date | string
  id: number
  deleteStatus: number
}
