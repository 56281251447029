import service, { Response } from "@/api/request"
import { getToken } from "@/utils/auth"
import { VipParams } from "@/views/vip"
import axios from "axios"

const api = {
   /**
    * @description 成为会员-微信支付统一下单-生成二维码
    * @author Jinx
    * @date 2021-10-28 12:13:54
    * @param {VipParams} params
    * @returns {*}  {Promise<any>}
    */
   wxPayPayUrlMember(params: VipParams): Promise<Response> {
    const Authorization = getToken()
    return axios({
      url: '/mall-portal/wx_pay/wxPay/member',
      method: 'get',
      headers: {
        Authorization
      },
      responseType: 'arraybuffer',
      params
    })
  },
   /**
    * @description 三创优品-微信支付统一下单-生成二维码
    * @author Jinx
    * @date 2021-10-28 12:13:54
    * @param {WxPayPayUrlParams} params
    * @returns {*}  {Promise<any>}
    */
   wxPayPayUrl(params: WxPayPayUrlParams): Promise<Response> {
    const Authorization = getToken()
    return axios({
      url: '/mall-portal/wx_pay/wxPay/payUrl',
      method: 'get',
      headers: {
        Authorization
      },
      responseType: 'arraybuffer',
      params
    })
  },
  /**
   * @description 课程或活动报名-微信支付统一下单-生成二维码
   * @author Jinx
   * @date 2021-11-01 16:04:42
   * @param {{id: number, totalFee: string}} params
   * @returns {*}  {Promise<Response>}
   */
  sign_up_pay_url(params: {id: number, totalFee: string}): Promise<Response> {
    const Authorization = getToken()
    return axios({
      url: '/mall-portal/wx_pay/wxPay/sign_up_pay_url',
      method: 'get',
      headers: {
        Authorization
      },
      responseType: 'arraybuffer',
      params
    })
  },
  /**
   * @description 前台页面定时器查询是否已支付
   * @author Jinx
   * @date 2021-10-28 13:22:16
   * @param {{outTradeNo: string}} params
   * @returns {*}  {Promise<Response>}
   */
  wxPayPayStatus(params: {outTradeNo: string}): Promise<Response> {
    return service({
      url: '/wx_pay/wxPay/payStatus',
      method: 'get',
      params
    })
  },
  /**
   * @description wxPayOther
   * @author Jinx
   * @date 2021-11-28 13:05:19
   * @param {WxPayOther} params
   * @returns {*}  {Promise<Response>}
   */
  wx_pay_other(params: WxPayOther): Promise<Response> {
    return service({
      url: '/wx_pay/wx_pay_other',
      method: 'get',
      params
    })
  },
}

export default api

export interface WxPayPayUrlParams {
  orderId: number | string
  orderSn: number | string
  totalFee: number | string
}

export interface WxPayOther {
  id?: string | number
  totalFee?: string | number
  url?: string
  cardId?: string
  type?: string
  username?: string
}
