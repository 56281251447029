import service, { Response } from "@/api/request"

const api = {
  /**
   * @description 查询所有会员等级
   * @author Jinx
   * @date 2021-10-21 16:52:52
   * @returns {*}  {Promise<Response>}
   */
  memberLevelList(): Promise<Response> {
    return service({
      url: '/select/getMemberLevel',
      method: 'get'
    })
  },
}

export default api
