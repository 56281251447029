import service, { Response } from "@/api/request"
import { RequestParams } from "@/models/type"
import { UmsAddress } from "@/views/address"

const api = {
  /**
   * @description 显示所有收货地址
   * @author Jinx
   * @date 2021-10-26 16:24:57
   * @returns {*}  {Promise<Response>}
   */
  memberAddressList (): Promise<Response> {
    return service({
      url: '/member/address/list',
      method: 'get'
    })
  },
  /**
   * @description 添加收货地址
   * @author Jinx
   * @date 2021-10-27 09:51:12
   * @param {UmsAddress} data
   * @returns {*}  {Promise<Response>}
   */
  memberAddressAdd (data: UmsAddress): Promise<Response> {
    return service({
      url: '/member/address/add',
      method: 'post',
      data
    })
  },
  /**
   * @description 修改收货地址
   * @author Jinx
   * @date 2021-10-27 10:16:44
   * @param {UmsAddress} data
   * @returns {*}  {Promise<Response>}
   */
  memberAddressUpdate (data: UmsAddress): Promise<Response> {
    return service({
      url: `/member/address/update/${data.id}`,
      method: 'post',
      data
    })
  },
  /**
   * @description 删除收货地址
   * @author Jinx
   * @date 2021-10-27 10:19:35
   * @param {number} id
   * @returns {*}  {Promise<Response>}
   */
  memberAddressDelete (id: number): Promise<Response> {
    return service({
      url: `/member/address/delete/${id}`,
      method: 'post'
    })
  },
  /**
   * @description 获取收货地址详情
   * @author Jinx
   * @date 2021-10-27 10:40:23
   * @param {number} id
   * @returns {*}  {Promise<Response>}
   */
  memberAddress (id: number): Promise<Response> {
    return service({
      url: `/member/address/${id}`,
      method: 'get'
    })
  },
  /**
   * @description 获取用户优惠券列表
   * @author Jinx
   * @date 2021-10-27 13:36:14
   * @param {{useStatus?: number}} params
   * @returns {*}  {Promise<Response>}
   */
  couponList (params: {useStatus?: number}): Promise<Response> {
    return service({
      url: `/member/coupon/list`,
      method: 'get',
      params
    })
  },
  /**
   * @description 获取用户优惠券历史列表
   * @author Jinx
   * @date 2021-10-27 13:54:20
   * @param {{useStatus?: number}} params
   * @returns {*}  {Promise<Response>}
   */
  couponListHistory (params: {useStatus?: number}): Promise<Response> {
    return service({
      url: `/member/coupon/listHistory`,
      method: 'get',
      params
    })
  },
  /**
   * @description 获取优惠券列表
   * @author Jinx
   * @date 2021-10-27 14:54:31
   * @param {RequestParams} params
   * @returns {*}  {Promise<Response>}
   */
  couponGetList (params: RequestParams): Promise<Response> {
    return service({
      url: `/member/coupon/getList`,
      method: 'get',
      params
    })
  },
  /**
   * @description 领取指定优惠券
   * @author Jinx
   * @date 2021-10-27 15:16:47
   * @param {number} couponId
   * @returns {*}  {Promise<Response>}
   */
  memberCouponAdd (couponId: number): Promise<Response> {
    return service({
      url: `/member/coupon/add/${couponId}`,
      method: 'post'
    })
  }
}

export default api
