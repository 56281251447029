import SvgIcon from "@/components/SvgIcon";
import { Options, Vue } from "vue-class-component";
import { mapGetters } from "vuex";

@Options({
  computed: {
    ...mapGetters(['GetBackgroundImgUrlBottom'])
  }
})
export default class Footer extends Vue {
  public GetBackgroundImgUrlBottom?: string

  public static link = (): JSX.Element => {
    const linkList: Array<linkProps> = [
      { icon: 'Facebook', alt: 'Facebook', size: { width: '25px', heigth: '40px' }, path: 'https://facebook.com/Taosseract' },
      { icon: 'Instagram', alt: 'Instagram', size: { width: '40px', heigth: '40px' }, path: 'https://instagram.com/taosseract?utm_medium=copy_link' },
      // { icon: 'YouTube', alt: 'YouTube', size: {width: '43px', heigth: '34px'}, path: 'https://www.youtube.com/channel/UCEbO3CvLL5HecQlfyTtLDZg' },
      // { icon: 'Bilibili', alt: 'Bilibili', size: {width: '43px', heigth: '39px'}, path: 'https://b23.tv/OcwCD8' }
    ]
    return <div class="footer-link">
      {linkList.map(linkRow => {
        return <p
          class={`footer-${linkRow.icon}`}
          style={{
            width: linkRow.size.width,
            height: linkRow.size.heigth
          }}
          onClick={() => window.open(linkRow.path)}
        >
          <SvgIcon iconClass={linkRow.icon as string} />
        </p>
      })}
    </div>
  }

  render(): JSX.Element {
    return <footer
      class="footer"
      style={{
        background: this.GetBackgroundImgUrlBottom
      }}
    >
      <div class="footer-left">
        <h3 class="footer-title">聯繫我們</h3>
        <p class="footer-label">
          <span class="footer-name">香港青少年文創空間</span>
          <span class="footer-phone"><SvgIcon iconClass="phone" /> 25282202</span>
          <span class="footer-whatsapp"><SvgIcon iconClass="whatsapp" /> WhatsApp︰97610757</span>
          <span class="footer-fax"><SvgIcon iconClass="fax" /> 25282981</span>
          <span class="footer-email"><SvgIcon iconClass="email" /> Infohkyccs@gmail.com</span>
        </p>
        <p class="footer-address"><SvgIcon iconClass="address" /> 尖沙咀科學館道9號，新東海商業中心1樓106-107室</p>
        <Footer.link />
      </div>
      <div class="footer-right">
        <img class="footer-right-logo1" src='/xjlogo.png' alt="" />
        <img class="footer-right-logo" src='/logo.png' alt="" />
      </div>
    </footer>
  }
}

interface linkProps {
  icon?: string
  alt?: string
  size: {
    width?: string | number
    heigth?: string | number
  },
  path: string
}
