import service, { Response } from "@/api/request"

const api = {
  /**
   * @description 支付宝支付
   * @author Jinx
   * @date 2021-10-28 15:31:59
   * @param {PaymentPayParams} params
   * @returns {*}  {Promise<Response>}
   */
  paymentPay(params: PaymentPayParams): Promise<Response> {
    return service({
      url: '/payment/pay',
      method: 'post',
      params
    })
  },
  /**
   * @description 信用卡支付
   * @author Jinx
   * @date 2021-10-28 18:02:33
   * @param {PaymentRenderParams} params
   * @returns {*}  {Promise<Response>}
   */
  paymentRender(data: PaymentRenderParams): Promise<Response> {
    return service({
      url: '/payment/render',
      method: 'post',
      params: data,
      data
    })
  },
  /**
   * @description alipay
   * @author Jinx
   * @date 2021-11-28 14:08:08
   * @returns {*}  {Promise<Response>}
   */
  paymentAlipay(): Promise<Response> {
    return service({
      url: '/payment/alipay',
      method: 'post'
    })
  },
}
export default api

export interface PaymentPayParams {
  body?: string
  goodsId?: string | number
  goodsName?: string
  price?: string | number
  quantity?: string | number
  subject?: string
}

export interface PaymentRenderParams {
  map?: string
  orderId?: string | number
  orderSn?: string | number
  orderType?: string
  payType?: string
  price?: string | number
  title?: string
  email?: string
}
