// global/vue-meta/components.tsx
import router from '@/router'
import { watch } from '@vue/runtime-core'
import { Options, setup, Vue } from 'vue-class-component'
import { SlotScopeProperties, useMeta } from 'vue-meta'

@Options<VueMeta>({
  render () {
    const slots = {
      title: ({ content }: SlotScopeProperties) => {
        return content || 'SITE_NAME'
      },
    }

    // metainfo是全局组件，由vue-meta注入
    // 这里不可以使用 h('metainfo')，无法正确渲染 metainfo 组件。原因未知
    return <metainfo v-slots={slots} />
  },
})
export default class VueMeta extends Vue {
  protected meta = setup(() => {
    const { meta } = useMeta({
      title: '',
    })

    // 监听当前路由对象 修改 meta 信息
    watch(router.currentRoute, () => {
      const route = router.currentRoute.value

      const metaInfo = route.meta?.metaInfo ?? true

      // true：使用 route.meta.title
      // false：由页面组件通过 useMeta 修改
      // object：更新
      if (metaInfo === true) {
        meta.title = route.meta?.title || ''
      } else if (metaInfo && metaInfo !== false) {
        Object.assign(meta, metaInfo)
      }
    })

    return meta
  })
}
