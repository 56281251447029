import service, { Response } from "@/api/request"

const api = {
  /**
   * @description getStripeKey
   * @author Jinx
   * @date 2021-10-25 14:18:25
   * @returns {*}  {Promise<Response>}
   */
   getStripeKey(): Promise<Response> {
    return service({
      url: '/payment/getStripeKey',
      method: 'get'
    })
  }
}

export default api

