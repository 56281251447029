import { KeepAlive } from "@vue/runtime-core";
import { Vue } from "vue-class-component";
import { RouterView } from "vue-router";

export default class AppMain extends Vue {

  private keepAliveInclude: Array<string> = []

  render(): JSX.Element {
    return <section class="app-main">
        <RouterView>
          {
            ({ Component }: { Component: any }): JSX.Element => <KeepAlive include={ this.keepAliveInclude }>
              <Component />
            </KeepAlive>
          }
        </RouterView>
    </section>
  }
}
