import service, { Response } from "@/api/request"
import { TeamMemberParam } from "@/views/mine/team"
const api = {
  /**
   * @description 创建账户
   * @author Jinx
   * @date 2021-11-05 16:30:07
   * @param {TeamMemberParam} data
   * @returns {*}  {Promise<Response>}
   */
  teamAddMember (data: TeamMemberParam): Promise<Response> {
    return service({
      url: '/team/addMember',
      method: 'post',
      data
    })
  },
  /**
   * @description 删除账户
   * @author Jinx
   * @date 2021-11-05 16:30:35
   * @param {Array<number>} data
   * @returns {*}  {Promise<Response>}
   */
  teamDelMember (data: Array<number>): Promise<Response> {
    return service({
      url: '/team/delMember',
      method: 'post',
      data
    })
  },
  /**
   * @description 根據團隊id獲取所擁有的會員列表
   * @author Jinx
   * @date 2021-11-05 16:48:46
   * @param {{id: number}} params
   * @returns {*}  {Promise<Response>}
   */
  getMemberInfoByTeamId (params: {id: number}): Promise<Response> {
    return service({
      url: '/team/getMemberInfoByTeamId',
      method: 'get',
      params
    })
  },
}

export default api
