import api from '@/api'
import { debounceClick } from '@/utils'
import { ElNotification } from 'element-plus'
import { ElFormItemContext } from 'element-plus/lib/el-form'
import { defineComponent, ref, toRefs, reactive } from 'vue'
import Hcaptcha from './Hcaptcha.vue'

/* 绑定账号 */
const ResPas = defineComponent({

  props: {
    resPasBool: {
      type: Boolean,
      default: false,
      required: true
    },
    onClose: Function,
  },
  emits:['close'],
  setup(prop, { emit }) {

    const validatePass = (rule: any, value: any, callback: any) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (resPasForm.confirmPassword !== '') {
          const __register_form = accountBindRef.value as any
          __register_form.validateField('confirmPassword')
        }
        callback()
      }
    };
    const validatePass2 = (rule: any, value: any, callback: any) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== resPasForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }

    const passwordTextBool = ref(false)

    const { resPasBool } = toRefs(prop)
    const type = ref('email')
    const checkBool = ref('')

    // 设置一个验证码标识数
    const authSum = ref(0) // 0 - 60 s
    const timer = ref({}) // 定时器
    const isHcaptcha = ref(false)

    const resPasForm = reactive({
      username: '',
      code: '',
      password: '',
      confirmPassword: '',
    })

    const usernameValidate = (rule: any, value: any, callback: any) => {
      if (value === '') {
        callback(new Error(`请输入${type.value === 'email' ? '邮箱' : '手机号'}`));
      } else {
        callback()
      }
    }

    const resPasFormRules = {
      username: [
        { validator: usernameValidate, required: true}
      ],
      code: [
        {required: true, message: '请输入验证码'}
      ],
      password: [
        { validator: validatePass, required: true}
      ],
      confirmPassword: [
        {validator: validatePass2, required: true}
      ]
    }
    const accountBindRef = ref(null)

    function accountBindClose(): void {
      const accountBindRefs = accountBindRef.value as any
      accountBindRefs.resetFields()
      emit('close')
    }

    function submit(): void {
      const accountBindRefs = accountBindRef.value as any
      const __account_bind = accountBindRefs as ElFormItemContext
      __account_bind.validate(v => {
        if (v) {
          const obj = Object.assign({}, resPasForm)
          api.forgetPassword(obj).then(res => {
            ElNotification({
              type: 'success',
              message: '修改密码成功'
            })
            accountBindClose()
          }).catch(() => {
            accountBindClose()
          })
        } else {
          return false
        }
      })
    }

    const authCode = {
      suffix: () => {
        if(checkBool.value) {
          return <div/>
        }else {
          return authSum.value
            ? <span class="login_register-auth">{authSum.value}秒后重新发送</span>
            : <span class="login_register-auth" onClick={() => debounceClick(hcaptchaChange)}>发送验证码</span>
        }
      }
    }

    function hcaptchaChange() {
      if(!resPasForm.username) {
        ElNotification({
          type: 'warning',
          message: `请输入${type.value === 'email' ? '邮箱' : '手机号'}`
        })
        return
      }
      isHcaptcha.value = true
    }

    async function checkAccount() {
      checkBool.value = ''
      const {data} = await api.if_register({ username: resPasForm.username })
      checkBool.value = !data ? '账号未被注册' : ''
    }

    function authIntever(captchaResponse: string): void {
      // 发送验证码
      api.sendGetCode({code: resPasForm.username, captchaResponse}).then(res => {
        ElNotification({
          type: 'success',
          message: '发送成功'
        })
        isHcaptcha.value = false
        if(authSum.value === 0) {
          authSum.value = 60
          clearInterval(timer.value as any)
          timer.value = setInterval(() => {
            authSum.value--
            !authSum.value && clearInterval(timer.value as any)
          }, 1000)
        }
      })
    }
    const slots = {
      suffix: () => <i
        class={[
          'el-input__icon pointer',
          passwordTextBool.value
            ? 'el-icon-lock'
            : 'el-icon-view'
        ]}
        onClick={() => passwordTextBool.value = !passwordTextBool.value}
      />
    }

    function onVerify(token: string): void {
      authIntever(token)
    }

    return () => <el-dialog
      title=""
      v-model={ resPasBool.value }
      width="528px"
      before-close={ accountBindClose }
      append-to-body={true}
      custom-class="login_register"
    >
      <h2 class="login_register-title">忘记密码</h2>
      {
        isHcaptcha.value
          ? <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Hcaptcha
                onVerify={onVerify}
              />
            </div>
          : <>
              <div class="login_register-choose">
                <div class={ type.value === 'email' && 'login_register-choose_active' } onClick={() => type.value = 'email'}>电邮</div>
                <div class={ type.value === 'phone' && 'login_register-choose_active' } onClick={() => type.value = 'phone'}>手机号</div>
              </div>
              <el-form
                ref={ accountBindRef }
                model={ resPasForm }
                rules={ resPasFormRules }
                label-position="top"
              >
                {
                  type.value === 'email'
                    ? <el-form-item label="" prop="username" error={checkBool.value}>
                    <el-input
                      v-model={ resPasForm.username }
                      clearable
                      placeholder="请输入邮箱"
                      v-slots={ authCode }
                      onInput={ () => debounceClick(checkAccount) }
                    />
                  </el-form-item>
                    : <el-form-item label="" prop="username" error={checkBool.value}>
                    <el-input
                      v-model={ resPasForm.username }
                      clearable
                      placeholder="请输入手机号"
                      v-slots={ authCode }
                      onInput={ () => debounceClick(checkAccount) }
                    />
                  </el-form-item>
                }
                <el-form-item label="" prop="code">
                  <el-input
                    v-model={ resPasForm.code }
                    clearable
                    placeholder="请输入验证码"
                  />
                </el-form-item>
                <el-form-item label="密码" prop="password">
                  <el-input
                    type={ passwordTextBool.value ? 'text' : 'password' }
                    v-model={ resPasForm.password }
                    clearable
                    placeholder="请输入密码"
                    v-slots={slots}
                  />
                </el-form-item>
                <el-form-item label="确认密码" prop="confirmPassword">
                  <el-input
                    type={ passwordTextBool.value ? 'text' : 'password' }
                    v-model={ resPasForm.confirmPassword }
                    clearable
                    placeholder="请再次输入密码"
                    v-slots={slots}
                  />
                </el-form-item>
              </el-form>
              <div class="login_register-submit" onClick={() => debounceClick(submit)}>确认</div>
            </>
      }
    </el-dialog>
  }
})

export default ResPas
