import service, { Response } from "@/api/request"
import { RequestParams } from "@/models/type"

const api = {
  /**
   * @description 根据购物车信息生成订单
   * @author Jinx
   * @date 2021-10-27 16:29:46
   * @param {OrderParam} data
   * @returns {*}  {Promise<Response>}
   */
  orderGenerateOrder (data: OrderParam): Promise<Response> {
    return service({
      url: '/order/generateOrder',
      method: 'post',
      data
    })
  },
  /**
   * @description 按状态分页获取用户订单列表
   * @author Jinx
   * @date 2021-10-27 16:55:13
   * @param {RequestParams} params
   * @returns {*}  {Promise<Response>}
   */
  orderList (params: RequestParams): Promise<Response> {
    return service({
      url: '/order/list',
      method: 'get',
      params: {
        ...params,
        status: -1
      }
    })
  },
  /**
   * @description 根据ID获取订单详情
   * @author Jinx
   * @date 2021-10-27 17:59:38
   * @param {number} orderId
   * @returns {*}  {Promise<Response>}
   */
  orderDetail (orderId: number): Promise<Response> {
    return service({
      url: `/order/detail/${orderId}`,
      method: 'get'
    })
  },

  /**
   * @description 用户确认收货
   * @author Jinx
   * @date 2021-10-27 18:28:36
   * @param {{orderId: number}} params
   * @returns {*}  {Promise<Response>}
   */
  confirmReceiveOrder (orderId: number): Promise<Response> {
    return service({
      url: `/order/confirmReceiveOrder/${orderId}`,
      method: 'get'
    })
  },
  /**
   * @description 用户支付成功的回调
   * @author Jinx
   * @date 2021-11-02 11:08:00
   * @param {OrderPaySuccessParams} params
   * @returns {*}  {Promise<Response>}
   */
  orderPaySuccess (params: OrderPaySuccessParams): Promise<Response> {
    return service({
      url: `/order/paySuccess`,
      method: 'get',
      params
    })
  },
  generateOrderByPoint (params: UmsGenerateOrderByPoint): Promise<Response> {
    return service({
      url: `/order/generateOrderByPoint`,
      method: 'post',
      params
    })
  }
}

export default api

/**
 * @description 使用积分购买商品
 * @author Jinx
 * @date 2021-11-11 15:02:02
 * @export
 * @interface UmsGenerateOrderByPoint
 */
export interface UmsGenerateOrderByPoint {
  addressId?: number
  productId?: number
  skuId?: number
}

export interface OrderParam {
  cartIds?: number[]
	couponId?: number | string
	memberReceiveAddressId?: number | string
	payType?: number | string
	useIntegration?: number
}

export interface OrderPaySuccessParams {
  no: string
  orderId: string
  payId: string
  payType: string
}
