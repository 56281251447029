import SvgIcon from "@/components/SvgIcon";
import { LayoutStoreRow } from "@/store/modules/layout";
import { ElBacktop } from "element-plus";
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { mapState } from "vuex";
import AppMain from "./components/AppMain";
import FixedSet from "./components/FixedSet";
import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar";

@Options({
  computed: mapState(['layout'])
})
export default class Layout extends Vue {
  public layout?: LayoutStoreRow

  toTop(): void {
    const __backtop = this.$refs.backtop as any
    __backtop.$el?.click()
  }

  /**
   * @description 监听数值 置顶
   * @author Jinx
   * @date 2021-10-28 10:31:15
   * @param {number} num
   * @memberof Layout
   */
  @Watch('layout.backNum', { deep: true, immediate: true})
  backNumPlus(num: number): void {
    if(num) {
      this.toTop()
    }
  }

  render(): JSX.Element {
    return <div style={{ filter: this.layout?.layout_filter ? 'blur(3px)' : 'none' }}>
      <Sidebar/>
      <div class="main-container">
        <AppMain/>
        <Footer/>
        <ElBacktop ref="backtop" target=".main-container">
          <div class="main-container-backtop">
            <SvgIcon iconClass="top"/>
          </div>
        </ElBacktop>
        {/* <FixedSet /> */}
      </div>
    </div>
  }
}
