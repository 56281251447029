import api from '@/api'
import { debounceClick } from '@/utils'
import { ElNotification } from 'element-plus'
import { ElFormItemContext } from 'element-plus/lib/el-form'
import { defineComponent, ref, toRefs, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import Hcaptcha from './Hcaptcha.vue'

/* 绑定账号 */
const AccountBind = defineComponent({

  props: {
    accountBindBool: {
      type: Boolean,
      default: false,
      required: true
    },
    onClose: Function,
    onBindPhoneOrEmail: Function
  },
  emits:['close', 'bindPhoneOrEmail'],
  setup(prop, { emit }) {
    const store = useStore()

    const route = useRoute()
    const validatePass = (rule: any, value: any, callback: any) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (loginForm.confirmPassword !== '') {
          const __register_form = accountBindRef.value as any
          __register_form.validateField('confirmPassword')
        }
        callback()
      }
    };
    const validatePass2 = (rule: any, value: any, callback: any) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== loginForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }

    const submitType = ref('check')
    const passwordTextBool = ref(false)

    const { accountBindBool } = toRefs(prop)
    const type = ref('email')
    const checkBool = ref('')

    // 设置一个验证码标识数
    const authSum = ref(0) // 0 - 60 s
    const timer = ref({}) // 定时器

    const accountBindForm = reactive({
      username: '',
      code: '',
      account: '',
      source: '',
      uuid: ''
    })

    const loginForm = reactive({
      password: '',
      confirmPassword: '',
    })

    const usernameValidate = (rule: any, value: any, callback: any) => {
      if (value === '') {
        callback(new Error(`请输入${type.value === 'email' ? '邮箱' : '手机号'}`));
      } else {
        callback()
      }
    }

    const accountBindRules = {
      username: [
        { validator: usernameValidate, required: true}
      ],
      code: [
        {required: true, message: '请输入验证码'}
      ]
    }
    const loginFormRules = {
      password: [
        { validator: validatePass, required: true}
      ],
      confirmPassword: [
        {validator: validatePass2, required: true}
      ]
    }
    const accountBindRef = ref(null)
    const isHcaptcha = ref(false)

    function accountBindClose(): void {
      emit('close')
    }

    function submit(): void {
      const accountBindRefs = accountBindRef.value as any
      const __account_bind = accountBindRefs as ElFormItemContext
      __account_bind.validate(v => {
        if (v) {
          if(submitType.value === 'check') {
            const obj = Object.assign({}, accountBindForm)
            obj.account = route.query.account as string
            obj.source = route.query.source as string
            obj.uuid = route.query.uuid as string
            api.verifyCode(obj).then(res => {
              if(res.data.token) {
                const token = res.data.tokenHead + res.data.token
                store.dispatch('setToken', token)
                emit('close')
              } else {
                submitType.value = 'submit'
              }
            })
          } else {
            emit('bindPhoneOrEmail', {...loginForm, username: accountBindForm.username})
          }
        } else {
          return false
        }
      })
    }

    const authCode = {
      suffix: () => {
        if(checkBool.value) {
          return <div/>
        }else {
          return authSum.value
            ? <span class="login_register-auth">{authSum.value}秒后重新发送</span>
            : <span class="login_register-auth" onClick={() => debounceClick(hcaptchaChange)}>发送验证码</span>
        }
      }
    }

    function hcaptchaChange() {
      if(!accountBindForm.username) {
        ElNotification({
          type: 'warning',
          message: `请输入${type.value === 'email' ? '邮箱' : '手机号'}`
        })
        return
      }
      isHcaptcha.value = true
    }

    function authIntever(captchaResponse: string): void {
      // 发送验证码
      api.sendGetCode({code: accountBindForm.username, captchaResponse}).then(res => {
        ElNotification({
          type: 'success',
          message: '发送成功'
        })
        isHcaptcha.value = false
        if(authSum.value === 0) {
          authSum.value = 60
          clearInterval(timer.value as any)
          timer.value = setInterval(() => {
            authSum.value--
            !authSum.value && clearInterval(timer.value as any)
          }, 1000)
        }
      })
    }

    async function checkAccount() {
      checkBool.value = ''
      const {data} = await api.checkAccount({ username: accountBindForm.username, source: route.query.source as string })
      checkBool.value = data ? '该账号已经被注册' : ''
    }

    const slots = {
      suffix: () => <i
        class={[
          'el-input__icon pointer',
          passwordTextBool.value
            ? 'el-icon-lock'
            : 'el-icon-view'
        ]}
        onClick={() => passwordTextBool.value = !passwordTextBool.value}
      />
    }

    function onVerify(token: string): void {
      authIntever(token)
    }

    return () => <el-dialog
      title=""
      v-model={ accountBindBool.value }
      width="528px"
      before-close={ accountBindClose }
      append-to-body={true}
      custom-class="login_register"
    >
      <h2 class="login_register-title">{ submitType.value === 'check' ?  '绑定账号' : '设置密码'}</h2>
      { submitType.value === 'check' && <div class="login_register-choose">
        <div class={ type.value === 'email' && 'login_register-choose_active' } onClick={() => type.value = 'email'}>电邮</div>
        <div class={ type.value === 'phone' && 'login_register-choose_active' } onClick={() => type.value = 'phone'}>手机号</div>
      </div> }
      {
        isHcaptcha.value
          ? <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Hcaptcha
                onVerify={onVerify}
              />
            </div>
          : <el-form
              ref={ accountBindRef }
              model={ submitType.value === 'check' ? accountBindForm : loginForm }
              rules={ submitType.value === 'check' ? accountBindRules : loginFormRules }
              label-position="top"
            >
              {
                submitType.value === 'check' && (
                  type.value === 'email'
                    ? <el-form-item label="" prop="username" error={checkBool.value}>
                    <el-input
                      v-model={ accountBindForm.username }
                      clearable
                      placeholder="请输入邮箱"
                      v-slots={ authCode }
                      onInput={ () => debounceClick(checkAccount) }
                    />
                  </el-form-item>
                    : <el-form-item label="" prop="username" error={checkBool.value}>
                    <el-input
                      v-model={ accountBindForm.username }
                      clearable
                      placeholder="请输入手机号"
                      v-slots={ authCode }
                      onInput={ () => debounceClick(checkAccount) }
                    />
                  </el-form-item>
                )
              }
              { submitType.value === 'check' && <el-form-item label="" prop="code">
                <el-input
                  v-model={ accountBindForm.code }
                  clearable
                  placeholder="请输入验证码"
                />
              </el-form-item> }
              { submitType.value === 'submit' && <>
                <el-form-item label="密码" prop="password">
                  <el-input
                    type={ passwordTextBool.value ? 'text' : 'password' }
                    v-model={ loginForm.password }
                    clearable
                    placeholder="请输入密码"
                    v-slots={slots}
                  />
                </el-form-item>
                <el-form-item label="确认密码" prop="confirmPassword">
                  <el-input
                    type={ passwordTextBool.value ? 'text' : 'password' }
                    v-model={ loginForm.confirmPassword }
                    clearable
                    placeholder="请再次输入密码"
                    v-slots={slots}
                  />
                </el-form-item>
              </> }
            </el-form>
      }
      <div class="login_register-submit" onClick={() => debounceClick(submit)}>确认</div>
    </el-dialog>
  }
})

export default AccountBind
