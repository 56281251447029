import router from './router'
import store from './store'
import { equipmentType, formatSearch } from './utils'
import { getToken } from './utils/auth'

router.beforeEach(async(to, from, next) => {
  if(
      equipmentType()
      && !to.path.includes('mine')
      && !to.path.includes('address')
      && !to.path.includes('vip')
      && !to.path.includes('signup')
      && !to.path.includes('pay')
      && !to.path.includes('shop_card')
    ) {
    const host = window.location.host
    const hostArr = host.split('.')
    hostArr[0] = 'mobile'
    // console.log(window.location.protocol + '//' + hostArr.join('.'))
    window.location.href = 'https://mobile.taosseract.com/'  // --- 正式环境
    // window.location.href = 'http://localhost:54177/pages/activity/activity' // ---  开发环境
    return
  }
  getUserInfo(next)
})

function getUserInfo(next: any): void {
  // 判断是否存在token
  const startVal = window.location.href.indexOf('?')
  // const urlToken = decodeURIComponent(formatSearch(window.location.search)?.token)
  const urlToken = decodeURIComponent(formatSearch(window.location.href.slice(startVal))?.token)
  const token = getToken()
  if(!urlToken || urlToken === 'undefined'){
    if(token && token !== 'undefined') {
      store.dispatch('setToken', token)
      next()
    } else {
      store.dispatch('resetToken')
      next()
    }
  } else {
    store.dispatch('setToken', urlToken || token)
    next()
  }
}

