import Layout from "@/layout";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const Redirect = () => import('@/views/redirect/index.vue')
const Home = () => import('@/views/home') // 首页页面组件 ，现将活动页面作为首页展示
const Work = () => import('@/views/work')
const WorkDetail = () => import('@/views/work/detail')

/* 活动 */
const Activity = () => import('@/views/activity')
const ActivityActivity = () => import('@/views/activity/activity')
const ActivityShow = () => import('@/views/activity/show')
const ActivityShowDetail = () => import('@/views/activity/show/detail')
const ActivityActivityDetail = () => import('@/views/activity/activity/detail')
const ActivityActivitySignup = () => import('@/views/activity/activity/signup')

/* 课程 */
const Subject = () => import('@/views/subject')
const SubjectDetail = () => import('@/views/subject/detail')
const SubjectSignup = () => import('@/views/subject/signup')

/* 三创产品 */
const Sanchuang = () => import('@/views/sanchuang')
const SanchuangDetail = () => import('@/views/sanchuang/detail')
const SanchuangPay = () => import('@/views/sanchuang/pay')

/* 地址 */
const Address = () => import('@/views/address')

const About = () => import('@/views/about')
const Mine = () => import('@/views/mine')
const MineSubject = () => import('@/views/mine/subject')
const MineActivity = () => import('@/views/mine/activity')
const MineOrder = () => import('@/views/mine/order')
const MineVoucher = () => import('@/views/mine/voucher')
const MineIntegral = () => import('@/views/mine/integral')
const MineSite = () => import('@/views/mine/site')
const MineTeam = () => import('@/views/mine/team')
const MineTeamInfo = () => import('@/views/mine/team_info')

/* 购物车 */
const ShopCard = () => import('@/views/shopCard')

/* 会员相关 */
const Vip = () => import('@/views/vip')
const VipIntegral = () => import('@/views/vip/integral')

const routes: Array<RouteRecordRaw> = [
  {
    path: '/redirect',
    component: Layout,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: Redirect
      }
    ]
  },
  {
    path: '/',
    name: 'BasicLayout',
    component: Layout,
    // redirect: '/activity',
    redirect: '/home',
    children: [
      {
        path: 'home',
        component: Home,
        name: 'Home',
        meta: { title: '空間首頁', hidden: true }
      },
      {
        path: 'subject',
        component: Subject,
        name: 'Subject',
        meta: { title: '空間課程', hidden: true },
        children: [
          {
            path: 'detail/:id',
            component: SubjectDetail,
            name: 'SubjectDetail',
            meta: {title: '課程詳情', hidden: true},
          },
          {
            path: 'signup',
            component: SubjectSignup,
            name: 'SubjectSignup',
            meta: {title: '課程報名', hidden: true},
          }
        ]
      },
      {
        path: 'activity',
        component: Activity,
        name: 'Activity',
        redirect: '/activity/activity',
        meta: { title: '空間活動', hidden: true },
        children: [
          {
            path: 'activity',
            component: ActivityActivity,
            meta: {title: '活動', hidden: true},
            children: [
              {
                path: 'detail/:id',
                component: ActivityActivityDetail,
                name: 'ActivityActivityDetail',
                meta: {title: '活動詳情', hidden: true},
              },
              {
                path: 'signup',
                component: ActivityActivitySignup,
                name: 'ActivityActivitySignup',
                meta: {title: '活動報名', hidden: true},
              }
            ]
          },
          {
            path: 'show',
            component: ActivityShow,
            meta: {title: '展覽', hidden: true},
            children: [
              {
                path: 'detail/:id',
                component: ActivityShowDetail,
                name: 'ActivityShowDetail',
                meta: {title: '展覽詳情', hidden: true},
              }
            ]
          },
        ]
      },
      {
        path: 'work',
        component: Work,
        name: 'Work',
        meta: { title: '作品展示', hidden: true },
        children: [
          {
            path: 'detail/:id',
            component: WorkDetail,
            name: 'WorkDetail',
            meta: {title: '作品詳情', hidden: true},
          }
        ]
      },
      {
        path: 'sanchuang',
        component: Sanchuang,
        name: 'Sanchuang',
        meta: { title: '三創產品', hidden: true },
        children: [
          {
            path: 'detail/:id',
            component: SanchuangDetail,
            name: 'SanchuangDetail',
            meta: {title: '產品詳情', hidden: true},
          },
          {
            path: 'pay',
            component: SanchuangPay,
            name: 'SanchuangPay',
            meta: {title: '購買產品', hidden: true},
          }
        ]
      },
      {
        path: 'about',
        component: About,
        name: 'About',
        meta: { title: '關於我們', hidden: true }
      },
      {
        path: 'shop_card',
        component: ShopCard,
        name: 'ShopCard',
        meta: { title: '購物車', hidden: true }
      },
      {
        path: 'address',
        component: Address,
        name: 'Address',
        meta: { title: '地址管理', hidden: true }
      },
      {
        path: 'vip',
        component: Vip,
        name: 'Vip',
        meta: { title: '空間會員', hidden: true }
      },
      {
        path: 'vip_integral',
        component: VipIntegral,
        name: 'VipIntegral',
        meta: { title: '積分權益', hidden: true }
      },
      {
        path: 'mine',
        component: Mine,
        name: 'Mine',
        meta: { title: '個人中心', hidden: true },
        redirect: '/mine/subject',
        children: [
          {
            path: 'subject',
            component: MineSubject,
            name: 'MineSubject',
            meta: { title: '我的課程', hidden: true }
          },
          {
            path: 'activity',
            component: MineActivity,
            name: 'MineActivity',
            meta: { title: '我的活動', hidden: true }
          },
          {
            path: 'order',
            component: MineOrder,
            name: 'MineOrder',
            meta: { title: '訂單', hidden: true }
          },
          {
            path: 'voucher',
            component: MineVoucher,
            meta: { title: '代金卷', hidden: true }
          },
          {
            path: 'integral',
            component: MineIntegral,
            name: 'MineIntegral',
            meta: { title: '積分商城', hidden: true }
          },
          {
            path: 'site',
            component: MineSite,
            name: 'MineSite',
            meta: { title: '賬戶設定', hidden: true }
          },
          {
            path: 'team_info',
            component: MineTeamInfo,
            name: 'MineTeamInfo',
            meta: { title: '賬戶信息', hidden: true }
          },
          {
            path: 'team',
            component: MineTeam,
            name: 'MineTeam',
            meta: { title: '團隊管理', hidden: true }
          }
        ]
      },
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  // history: createWebHashHistory(), // hash 模式
  routes,
});

export default router;
