import service, { Response } from "@/api/request"
const api = {

  /**
   * @description 报名生成订单
   * @author Jinx
   * @date 2021-11-01 15:30:35
   * @param {smsSubjectActivityMemberParam} data
   * @returns {*}  {Promise<Response>}
   */
  signUpCallBack (data: smsSubjectActivityMemberParam): Promise<Response> {
    return service({
      url: '/signUp/signUpOrder',
      method: 'post',
      data
    })
  },
  /**
   * @description 报名成功之后的回调
   * @author Jinx
   * @date 2021-11-02 15:38:59
   * @param {{id: number}} params
   * @returns {*}  {Promise<Response>}
   */
  signUpSignUpCallBack (params: {id: string}): Promise<Response> {
    return service({
      url: '/signUp/signUpCallBack',
      method: 'post',
      params
    })
  },
}

export default api

export interface smsSubjectActivityMemberParam {
  cardId?: string,
	createTime?: string,
	email?: string,
	id?: number,
	memberId?: number,
	memberName?: string,
	phone?: string,
	targetId?: number,
	type?: string,
	updateTime?: Date | string
  price?: string
}
