import service, { Response } from "@/api/request"

const api = {
  /**
   * @description 评论商品
   * @author Jinx
   * @date 2021-10-31 16:54:10
   * @param {CommentParam} data
   * @returns {*}  {Promise<Response>}
   */
  commentComment (data: CommentParam[], orderId: number): Promise<Response> {
    return service({
      url: `/comment/comment/${orderId}`,
      method: 'post',
      data
    })
  },
  /**
   * @description 根据商品id获取评论
   * @author Jinx
   * @date 2021-11-01 14:55:51
   * @param {GetCommentsParams} params
   * @returns {*}  {Promise<Response>}
   */
  getComments (params: GetCommentsParams): Promise<Response> {
    return service({
      url: `/comment/getComments`,
      method: 'get',
      params
    })
  }
}

export default api

export interface CommentParam {
  content?: string
  pics?: string
  productId?: string
  productSn?: string
}

export interface GetCommentsParams {
  isMine: number
  productId?: number
  orderId?: string | number
  pageSize?: number
  pageNum?: number
}
