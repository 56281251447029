import service, { Response } from "@/api/request"
import { RequestParams } from "@/models/type"
import store from "@/store"
import { UserStoreRow } from "@/store/modules/user"

const api = {
  /**
   * @description 以树形结构获取所有商品分类
   * @author Jinx
   * @date 2021-10-25 14:18:25
   * @returns {*}  {Promise<Response>}
   */
  categoryTreeList (): Promise<Response> {
    return service({
      url: '/product/categoryTreeList',
      method: 'get'
    })
  },
  /**
   * @description 综合搜索、筛选、排序
   * @author Jinx
   * @date 2021-10-25 14:55:21
   * @param {ProductSearchParams} params
   * @returns {*}  {Promise<Response>}
   */
  productSearch (params: ProductSearchParams): Promise<Response> {
    const useStore = store.state as any
    const userStore = useStore.user as UserStoreRow
    return service({
      url: `/product/search/${userStore.userinfo.memberLevelId || 4}`,
      method: 'get',
      params
    })
  },
  /**
   * @description 获取前台商品详情
   * @author Jinx
   * @date 2021-10-25 16:31:52
   * @param {number} id
   * @returns {*}  {Promise<Response>}
   */
  productDetail (id: number): Promise<Response> {
    const useStore = store.state as any
    const userStore = useStore.user as UserStoreRow
    return service({
      url: `/product/detail/${id}/${userStore.userinfo.memberLevelId || 4}`,
      method: 'get'
    })
  },
}

export default api

export interface ProductSearchParams extends RequestParams {
  brandId?: number
  keyword?: string
  productCategoryId?: number | string
  sort?: any
}
