import service, { Response } from "@/api/request"

const api = {
  /**
   * @description 项目理念和空间愿景
   * @author Jinx
   * @date 2021-10-19 16:20:12
   * @returns {*}  {Promise<Response>}
   */
  getSpaceIntroduce(): Promise<Response> {
    return service({
      url: '/aboutUs/getSpaceIntroduce',
      method: 'get'
    })
  },
  /**
   * @description 获取团队成员列表
   * @author Jinx
   * @date 2021-10-19 16:20:13
   * @returns {*}  {Promise<Response>}
   */
  getTeamList(): Promise<Response> {
    return service({
      url: '/aboutUs/getTeamList',
      method: 'get'
    })
  },
  /**
   * @description 获取合作机构列表
   * @author Jinx
   * @date 2021-10-19 16:20:15
   * @returns {*}  {Promise<Response>}
   */
  getInstitutionList(): Promise<Response> {
    return service({
      url: '/aboutUs/getInstitutionList',
      method: 'get'
    })
  },
  /**
   * @description 获取友好连接
   * @author Jinx
   * @date 2021-10-19 16:20:16
   * @returns {*}  {Promise<Response>}
   */
  getCooperationList(): Promise<Response> {
    return service({
      url: '/aboutUs/getCooperationList',
      method: 'get'
    })
  }
}

export default api
