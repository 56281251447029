
import select from './modules/select'
import home from './modules/home'
import sso from './modules/sso'
import common from './modules/common'
import signUp from './modules/signUp'
import aboutUs from './modules/aboutUs'
import product from './modules/product'
import cart from './modules/cart'
import member from './modules/member'
import order from './modules/order'
import wx_pay from './modules/wx_pay'
import payment from './modules/payment'
import comment from './modules/comment'
import memberLevel from './modules/memberLevel'
import team from './modules/team'
import render from './modules/render'

const api = {
  ...select,
  ...home,
  ...sso,
  ...common,
  ...signUp,
  ...aboutUs,
  ...product,
  ...cart,
  ...member,
  ...order,
  ...wx_pay,
  ...payment,
  ...comment,
  ...memberLevel,
  ...team,
  ...render
}

export default api
