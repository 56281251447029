/**
 * @description 判断是不是外站域名
 * @author Jinx
 * @date 2021-09-05 15:08:02
 * @export
 * @param {string} path
 * @returns {*}  {boolean}
 */
 export function isExternal(path: string): boolean {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @description 判断邮箱是否正确
 * @author Jinx
 * @date 2021-08-13 14:19:49
 * @export
 * @param {string} email
 * @returns {*}  {boolean}
 */
export function isEmail(email: string): boolean {
  return /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(email)
}

/**
 * @description 判断英文名称是否正确
 * @author Jinx
 * @date 2021-08-20 13:52:55
 * @export
 * @param {string} enName
 * @returns {*}  {boolean}
 */
export function isEnName(enName: string): boolean {
  return /^[A-Z][a-z]*(\s[A-Z][a-z]*)*$/.test(enName)
}
