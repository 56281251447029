import api from "@/api"
import { removeToken, setToken } from "@/utils/auth"
import { UmsMemberParam } from "@/views/mine/site"
import { ActionContext } from "vuex"

const userStore = {
  state: {
    userinfo: {} as UserInfoRow,
    userBind: [] as Array<UserBindRow>,
    userTeam: {} as UserTeamRow,
    userLevel: {} as UserLevel,
    token: '',
    isUser: false
  } as UserStoreRow,
  mutations: {
    SET_INFO: (state: UserStoreRow, userinfo: UserInfoRow): void => {
      state.userinfo = userinfo
    },
    SET_BIND: (state: UserStoreRow, userBind: Array<UserBindRow>): void => {
      state.userBind = userBind
    },
    SET_TEAM: (state: UserStoreRow, userTeam: UserTeamRow): void => {
      state.userTeam = userTeam
    },
    SET_LEVEL: (state: UserStoreRow, userLevel: UserLevel): void => {
      state.userLevel = userLevel
    },
  },
  actions: {
    setToken({state, dispatch}: ActionContext<UserStoreRow, UserStoreRow>, token: string): void {
      state.token = token
      setToken(token)
      dispatch('getUserInfo')
    },
    setUserInfo({state}: ActionContext<UserStoreRow, UserStoreRow>, userInfo: UserInfoRow): void {
      state.userinfo = userInfo
    },
    setUserBind({state}: ActionContext<UserStoreRow, UserStoreRow>, userBind: Array<UserBindRow>): void {
      state.userBind = userBind
    },
    getUserInfo({commit, dispatch}: ActionContext<UserStoreRow, UserStoreRow>): Promise<UserInfoRow | null> {
      return new Promise((resolve, reject) => {
        api.sso_info().then(res => {
          if(res.data?.info.teamId > 0) {
            res.data.team.teamCount = res.data.teamCount
            commit('SET_TEAM', res.data?.team)
          }
          commit('SET_INFO', res.data?.info)
          commit('SET_BIND', res.data?.bind)
          commit('SET_LEVEL', res.data?.level)
          dispatch('isUserChange', Boolean(res.data))
          dispatch('changeImgUrl', res.data?.info?.bgImg || '')
          resolve(res.data)
        }).catch(() => {
          dispatch('isUserChange', false)
          dispatch('resetToken')
          reject(null)
        })
      })
    },
    resetToken({state, dispatch}: ActionContext<UserStoreRow, UserStoreRow>): Promise<boolean> {
      return new Promise(resolve => {
        state.token = ''
        dispatch('isUserChange', false)
        removeToken()
        resolve(true)
      })
    },
    isUserChange({state}: ActionContext<UserStoreRow, UserStoreRow>, isUser: boolean): void {
      state.isUser = isUser
    }
  },
  getters: {
    GetToken: (state: UserStoreRow): string | undefined => state.token,
    GetUserBool: (state: UserStoreRow): boolean => state.isUser,
    GetUserInfo: (state: UserStoreRow): UserInfoRow => state.userinfo,
    GetUserBind: (state: UserStoreRow): Array<UserBindRow> => state.userBind,
    GetUserTeam: (state: UserStoreRow): UserTeamRow => state.userTeam,
    GetUserLevel: (state: UserStoreRow): UserLevel => state.userLevel
  }
}

export default userStore

export interface UserStoreRow {
  userBind: Array<UserBindRow>,
  userinfo: UserInfoRow
  userTeam: UserTeamRow
  userLevel: UserLevel
  token?: string
  isUser: boolean
}

export interface UserInfoRow extends UmsMemberParam {
  attr?: string
  institution?: string
  icon?: string // 头像
  bgImg?: string // 背景
  createTime?: Date | string
  email?: string,
  memberLevelId?: number,
  status?: number,
  teamId?: number,
  ifTeam?: number
}

export interface UserBindRow {
  account: string
  createTime: Date | string
  id: number
  memberId: number
  source: string
  uuid: string
}

export interface UserLevel {
  discount?: number
  name?: string
}
export interface UserTeamRow {
  accountNumber: number
  address: string
  id: number
  cardId: string
  code: string
  createTime: string | Date
  deleteStatus: string
  gender: number
  leaderName: string
  phone: string
  status: string
  title: string
  email: string,
  teamCount: number
}
