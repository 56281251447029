import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from 'element-plus' // element-ui
import locale from 'element-plus/lib/locale/lang/zh-cn'
import 'dayjs/locale/zh-cn'
import 'default-passive-events'
import vueMetaManager from '@/global/vue-meta'
import VueSocialSharing from 'vue-social-sharing'

import '@/style/index.scss'
import api from "./api";

import './permission' // permission control


const app = createApp(App)

app.config.globalProperties.$api = api

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
app.use(store).use(router).use(ElementPlus, { locale }).use(vueMetaManager).use(VueSocialSharing).mount("#app");
