import api from "@/api";
import { BindPhoneOrEmailParams } from "@/api/modules/home";
import store from "@/store";
import { UserInfoRow, UserStoreRow } from "@/store/modules/user";
import { ElNotification } from "element-plus";
import { Options, Vue } from "vue-class-component";
import { RouterLink, RouterLinkProps } from "vue-router";
import { mapGetters, mapState } from "vuex";
import AccountBind from "./AccountBind";
import LoginRegister from "./LoginRegister";
import bgAvatar from '@/views/mine/assets/logo.png'
import { LayoutStoreRow } from "@/store/modules/layout";
import ResPas from "./ResPas";


@Options({
  computed: {
    ...mapState(['user', 'layout']),
    ...mapGetters(['GetUserBool', 'GetUserInfo', 'GetBackgroundImgUrlLeft'])
  }
})
export default class Sidebar extends Vue {
  public user?: UserStoreRow
  public layout?: LayoutStoreRow
  public GetUserBool?: boolean
  public GetUserInfo?: UserInfoRow
  public GetBackgroundImgUrlLeft?: string

  private linkList: Array<{ label?: string, props: RouterLinkProps }> = [
    { label: '首頁', props: { to: '/home', activeClass: 'sidebar-isActive' } },
    { label: '空間活動', props: { to: '/activity', activeClass: 'sidebar-isActive' } },
    { label: '作品展示', props: { to: '/work', activeClass: 'sidebar-isActive' } },
    { label: '三創產品', props: { to: '/sanchuang', activeClass: 'sidebar-isActive' } },
    { label: '關於我們', props: { to: '/about', activeClass: 'sidebar-isActive' } },
  ]
  public accountBindBool = false
  public resPasBool = false
  private type = 'login'

  logo(): JSX.Element {
    return <img src="/logo.png" class="pointer" alt="" onClick={ () => this.$router.push('/') } />
  }

  set(): JSX.Element {
    return <div class="sidebar-set">
      {/* <span class="pointer" onClick={this.loginShow}>登录</span>
      <span> / </span>
      <span class="pointer" onClick={this.registerShow}>注册</span> */}
    </div>
  }
  userInfo(): JSX.Element {
    const nickname = this.GetUserInfo?.username || this.GetUserInfo?.nickname || this.GetUserInfo?.email || this.GetUserInfo?.phone || '用户名'
    return <div class="sidebar-info" onClick={this.goMine}>
      <img src={ this.GetUserInfo?.icon || bgAvatar } alt={ nickname } />
      <span>{ nickname }
        {Boolean(this.GetUserInfo?.memberLevelId) && this.GetUserInfo?.memberLevelId !== 4 && <i>VIP</i>}
      </span>
    </div>
  }
  goMine(): void {
    this.$router.push({
      name: this.GetUserInfo?.memberLevelId ? 'Mine' : 'MineTeamInfo'
    })
  }

  link = (): JSX.Element => {
    return <ul class="sidebar-contaner">
        {this.linkList.map(item => (
          <li class="sidebar-cell">
            <RouterLink {...item.props} >{item.label}</RouterLink>
          </li>
        ))}
    </ul>
  }

  public loginShow(): void {
    // this.$store.dispatch('setLayoutFilter', true)
    this.type = 'login'
    this.$store.dispatch('loginShowChange', true)
  }
  registerShow(): void {
    // this.$store.dispatch('setLayoutFilter', true)
    this.type = 'register'
    this.$store.dispatch('loginShowChange', true)
  }

  loginRegisterBoolClose(): void {
    this.$store.dispatch('setLayoutFilter', false)
    this.$store.dispatch('loginShowChange', false)
    this.$router.go(0)
  }

  created(): void {
    if(this.$route.query.uuid) {
      // this.$store.dispatch('setLayoutFilter', true)  // 设置全局遮罩层
      this.accountBindBool = true
    }
  }
  accountBindClose(): void {
    this.$store.dispatch('setLayoutFilter', false)
    this.accountBindBool = false
  }

  onBindPhoneOrEmail(loginForm: { password: string, confirmPassword: string, username: string}): void {
    const obj = {
      ...this.$route.query,
      ...loginForm
    } as BindPhoneOrEmailParams

    api.bindPhoneOrEmail(obj).then(res => {
      ElNotification({
        title: '登录',
        message: '登录成功',
        type: 'success'
      })
      const token = res.data.tokenHead + res.data.token
      store.dispatch('setToken', token)
      this.accountBindClose()
    })
  }

  onResPas(): void {
    this.$store.dispatch('loginShowChange', false)
    this.resPasBool = true
  }

  resPasClose(): void {
    this.resPasBool = false
    this.$store.dispatch('loginShowChange', true)
  }

  render(): JSX.Element {
    return <div
      class="sidebar"
      style={{
        background: this.GetBackgroundImgUrlLeft
      }}
    >
      {this.logo()}
      {this.link()}
      { this.GetUserBool ? this.userInfo() : this.set()}
      {/* <LoginRegister
        type={ this.type }
        loginRegisterBool={this.layout?.loginShow as boolean}
        onClose={this.loginRegisterBoolClose}
        onToLogin={ () => this.type = 'login' }
        onToRegister={ () => this.type = 'register' }
        onResPas={this.onResPas}
      /> */}
      <AccountBind
        accountBindBool={ this.accountBindBool }
        onClose={ this.accountBindClose }
        onBindPhoneOrEmail={ this.onBindPhoneOrEmail }
      />
      <ResPas
        resPasBool={this.resPasBool}
        onClose={ this.resPasClose }
      />
    </div>
  }
}
