import { ActionContext } from "vuex"

export interface LayoutStoreRow {
  layout_filter: boolean
  backgroundImgUrlLeft: string
  backgroundImgUrlTop: string
  backgroundImgUrlBottom: string
  loginShow: boolean
  backNum: number
}

const layoutStore = {
  state: {
    layout_filter: false,
    backgroundImgUrlLeft: '#241B19',
    backgroundImgUrlTop: '#241B19',
    backgroundImgUrlBottom: '#241B19',
    loginShow: false,
    backNum: 0
  } as LayoutStoreRow,

  mutations: {
    SET_LAYOUTFILTER: (state: LayoutStoreRow, isBool: boolean): void => {
      state.layout_filter = isBool
    },
    SET_BACGROUNDIMGURL_LEFT: (state: LayoutStoreRow, url: string): void => {
      state.backgroundImgUrlLeft = url
    },
    SET_BACGROUNDIMGURL_TOP: (state: LayoutStoreRow, url: string): void => {
      state.backgroundImgUrlTop = url
    },
    SET_BACGROUNDIMGURL_BOTTOM: (state: LayoutStoreRow, url: string): void => {
      state.backgroundImgUrlBottom = url
    }
  },

  actions: {
    setLayoutFilter({ commit }: ActionContext<LayoutStoreRow, LayoutStoreRow>, isBool: boolean): void {
      commit('SET_LAYOUTFILTER', isBool)
    },
    backNumPlus({ state }: ActionContext<LayoutStoreRow, LayoutStoreRow>): void {
      state.backNum++
    },
    changeImgUrl({ commit }: ActionContext<LayoutStoreRow, LayoutStoreRow>, url: string): void {
      if(url) {
        commit('SET_BACGROUNDIMGURL_LEFT', `url(${url}) no-repeat left / auto 100%`)
        commit('SET_BACGROUNDIMGURL_TOP', `url(${url}) no-repeat center center / 100% auto`)
        commit('SET_BACGROUNDIMGURL_BOTTOM', `url(${url}) no-repeat center bottom / 100% auto`)

      } else {
        commit('SET_BACGROUNDIMGURL_LEFT', '#241B19')
        commit('SET_BACGROUNDIMGURL_TOP', '#241B19')
        commit('SET_BACGROUNDIMGURL_BOTTOM', '#241B19')
      }
    },
    /**
     * @description 是否开启登录框
     * @author Jinx
     * @date 2021-10-26 12:00:15
     * @param {ActionContext<LayoutStoreRow, LayoutStoreRow>} {state}
     * @param {boolean} bool
     */
    loginShowChange({state}: ActionContext<LayoutStoreRow, LayoutStoreRow>, bool: boolean): void {
      state.loginShow = bool
    }
  },
  getters: {
    GetBackgroundImgUrlLeft: (state: LayoutStoreRow): string => state.backgroundImgUrlLeft,
    GetBackgroundImgUrlTop: (state: LayoutStoreRow): string => state.backgroundImgUrlTop,
    GetBackgroundImgUrlBottom: (state: LayoutStoreRow): string => state.backgroundImgUrlBottom
  }
}

export default layoutStore
