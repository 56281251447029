import service, { Response } from "@/api/request"

const api = {
  /**
   * @description 发验证码
   * @author Jinx
   * @date 2021-11-12 18:04:47
   * @param {({code: string | number, captchaResponse: string})} data
   * @returns {*}  {Promise<Response>}
   */
  sendGetCode(data: {code: string | number, captchaResponse: string}): Promise<Response> {
    return service({
      url: '/common/sendGetCode',
      method: 'post',
      data
    })
  },
  /**
   * @description 文件上传OSS
   * @author Jinx
   * @date 2021-10-15 11:09:23
   * @param {FormData} data
   * @returns {*}  {Promise<Response>}
   */
  ossUpload(data: FormData): Promise<Response> {
    return service({
      url: '/common/ossUpload',
      method: 'post',
      data
    })
  },
  /**
   * @description 判断当前账号是否注册
   * @author Jinx
   * @date 2021-11-16 10:14:41
   * @param {{username: string}} params
   * @returns {*}  {Promise<Response>}
   */
  if_register(params: {username: string}): Promise<Response> {
    return service({
      url: '/common/if_register',
      method: 'get',
      params
    })
  },
}

export default api
