import service, { Response, ResponseData } from "@/api/request"

const api = {
  /**
   * @description 获取月份
   * @author Jinx
   * @date 2021-09-23 09:46:59
   * @param {{year: number}} params
   * @returns {*}  {Promise<Response>}
   */
  getMonth (params: {year: number}): Promise<Response> {
    return service({
      url: '/select/getMonth',
      method: 'get',
      params
    })
  },

  /**
   * @description 根据选项框id获取详细内容
   * @author Jinx
   * @date 2021-09-30 15:46:13
   * @param {number} id
   * @returns {*}  {Promise<Response>}
   */
  getSysSelectTypeOption (id: number): Promise<Response> {
    return service({
      url: `/select/getSysSelectTypeOption/${id}`,
      method: 'get'
    })
  }
}

export default api

export interface MonthData extends ResponseData {
  startMonth: number
  endMonth: number
  fullName: string
  year: number
}
