import service, { Response } from "@/api/request"
import { DefaultSetting } from "@/models/type"

const api = {
  /**
   * @description 添加商品到购物车
   * @author Jinx
   * @date 2021-10-26 11:20:40
   * @returns {*}  {Promise<Response>}
   */
  cartAdd (data: CartItem): Promise<Response> {
    return service({
      url: '/cart/add',
      method: 'post',
      data
    })
  },
  /**
   * @description 获取某个会员的购物车列表
   * @author Jinx
   * @date 2021-10-26 11:46:49
   * @returns {*}  {Promise<Response>}
   */
  cartList (): Promise<Response> {
    return service({
      url: '/cart/list/promotion',
      method: 'get'
    })
  },
  /**
   * @description 删除购物车中的某个商品
   * @author Jinx
   * @date 2021-10-26 14:21:51
   * @param {Array<number>} data
   * @returns {*}  {Promise<Response>}
   */
  cartDelete (data: Array<number>): Promise<Response> {
    return service({
      url: '/cart/delete',
      method: 'post',
      data
    })
  },
  /**
   * @description 修改购物车中某个商品的数量
   * @author Jinx
   * @date 2021-10-26 15:21:40
   * @param {{id: number, quantity: number}} params
   * @returns {*}  {Promise<Response>}
   */
  cartUpdateQuantity (params: {id: number, quantity: number}): Promise<Response> {
    return service({
      url: '/cart/update/quantity',
      method: 'get',
      params
    })
  }
}

export default api

export interface CartItem extends DefaultSetting {
  deleteStatus?: number
  memberId?: number
  memberNickname?: string
  modifyDate?: Date | string
  price?: number | string
  productAttr?: string,
	productBrand?: string,
	productCategoryId?: number,
	productId?: number,
	productName?: string,
	productPic?: string,
	productSkuCode?: string,
	productSkuId?: number,
	productSn?: string,
	productSubTitle?: string,
	quantity?: number
}
