import service, { Response } from "@/api/request"
import { LoginParams, RegisterParams } from "@/layout/components/LoginRegister"
import { RequestParams } from "@/models/type"
import { UmsMemberParam } from "@/views/mine/site"
import { UpdateEmail } from "@/views/mine/site/components/email"
import { UpdatePassword } from "@/views/mine/site/components/password"
import { UpdatePhone } from "@/views/mine/site/components/phone"

const api = {
  sso_login(params: LoginParams): Promise<Response> {
    return service({
      url: '/sso/login',
      method: 'post',
      params
    })
  },
  sso_register(params: RegisterParams): Promise<Response> {
    return service({
      url: '/sso/register',
      method: 'post',
      params
    })
  },
  /**
   * @description 会员编辑
   * @author Jinx
   * @date 2021-10-14 09:45:54
   * @param {UmsMemberParam} data
   * @returns {*}  {Promise<Response>}
   */
  sso_edit_info(data: UmsMemberParam): Promise<Response> {
    return service({
      url: '/sso/editInfo',
      method: 'post',
      data
    })
  },
  sso_info(): Promise<Response> {
    return service({
      url: '/sso/info',
      method: 'get'
    })
  },
  /**
   * @description 获取我报名的的课程或活动
   * @author Jinx
   * @date 2021-11-02 10:15:36
   * @param {GetOwnSubjectOrActivityParams} params
   * @returns {*}  {Promise<Response>}
   */
  getOwnSubjectOrActivity(params: GetOwnSubjectOrActivityParams): Promise<Response> {
    return service({
      url: '/sso/getOwnSubjectOrActivity',
      method: 'get',
      params
    })
  },
  /**
   * @description 解绑第三方
   * @author Jinx
   * @date 2021-10-14 15:06:32
   * @param {{source: string}} params
   * @returns {*}  {Promise<Response>}
   */
  sso_unbind(params: {source: string}): Promise<Response> {
    return service({
      url: '/sso/unbind',
      method: 'post',
      params
    })
  },
  /**
   * @description 修改密码
   * @author Jinx
   * @date 2021-10-14 15:06:58
   * @param {UpdatePassword} params
   * @returns {*}  {Promise<Response>}
   */
  updatePassword(params: UpdatePassword): Promise<Response> {
    return service({
      url: '/sso/updatePassword',
      method: 'post',
      params
    })
  },
  /**
   * @description 修改邮箱
   * @author Jinx
   * @date 2021-10-14 15:58:43
   * @param {UpdateEmail} params
   * @returns {*}  {Promise<Response>}
   */
  editEmail(params: UpdateEmail): Promise<Response> {
    return service({
      url: '/sso/editEmail',
      method: 'post',
      params
    })
  },

  /**
   * @description 修改手机
   * @author Jinx
   * @date 2021-10-14 15:59:16
   * @param {UpdatePhone} params
   * @returns {*}  {Promise<Response>}
   */
  editPhone(params: UpdatePhone): Promise<Response> {
    return service({
      url: '/sso/editPhone',
      method: 'post',
      params
    })
  },
  /**
   * @description 修改头像
   * @author Jinx
   * @date 2021-10-15 10:03:01
   * @param {{avatar: string}} params
   * @returns {*}  {Promise<Response>}
   */
  editAvatar(params: {avatar: string}): Promise<Response> {
    return service({
      url: '/sso/editAvatar',
      method: 'post',
      params
    })
  },
  /**
   * @description 修改背景图
   * @author Jinx
   * @date 2021-10-15 11:55:42
   * @param {{bgImage: string}} params
   * @returns {*}  {Promise<Response>}
   */
  editBgImage(params: {bgImage: string}): Promise<Response> {
    return service({
      url: '/sso/editBgImage',
      method: 'post',
      params
    })
  },
  /**
   * @description 会员支付成功之后的回调
   * @author Jinx
   * @date 2021-11-16 11:02:01
   * @param {UmsMemberCallback} params
   * @returns {*}  {Promise<Response>}
   */
  memberCallback(params: UmsMemberCallback): Promise<Response> {
    return service({
      url: '/sso/memberCallback',
      method: 'post',
      params
    })
  },
  /**
   * @description 忘记密码
   * @author Jinx
   * @date 2021-11-11 15:58:48
   * @param {{code?: string, confirmPassword?: string, password?: string, username?: string}} params
   * @returns {*}  {Promise<Response>}
   */
  forgetPassword(params: {code?: string, confirmPassword?: string, password?: string, username?: string}): Promise<Response> {
    return service({
      url: '/sso/forgetPassword',
      method: 'post',
      params
    })
  }
}

export default api

declare interface GetOwnSubjectOrActivityParams extends RequestParams {
  type: string
  status: number | string
}


export interface UmsMemberCallback {
  id: number | string
  payMoney: string
  payType: string | number
}
