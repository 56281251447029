import { commitT } from "@/models/type"
import { ElNotification } from "element-plus"
import debounce from 'lodash/debounce'

/**
 * @description 拉起下载动作
 * @author Jinx
 * @date 2021-04-04 12:08:47
 * @export
 * @param {string} __filename 下载文件名
 * @param {Blob} [__blob] blob集合
 */
export function downDom(__filename: string | undefined, __blob?: Blob): void {
  const elink = document.createElement('a')
  if('download' in elink) {
    elink.download = __filename as string
    elink.style.display = 'none'
    elink.href = URL.createObjectURL(__blob)
    document.body.appendChild(elink)
    elink.click()
    URL.revokeObjectURL(elink.href)
    document.body.removeChild(elink)
  } else {
    const winNavigator = window.navigator as any
    winNavigator.msSaveBlob(__blob, __filename)
  }
}


/**
 * @description 引入所有svg文件
 * @author Jinx
 * @date 2021-44-09 09:08:25
 * @export
 */
export function reqSvgs(): void {
  const requireAll = (requireContext: any) => requireContext.keys().map(requireContext)
  const svgFiles = require.context('@/icons/svg', false, /\.svg$/)
  requireAll(svgFiles)
}

/**
 * @description 引入所有icon文件
 * @author Jinx
 * @date 2021-08-23 10:07:43
 * @export
 */
export function reqAssetsSvgs(): void {
  const requireAssetsAll = (requireContext: any) => requireContext.keys().map(requireContext)
  const assetsSvgFiles = require.context('@/assets/svgs', false, /\.svg$/)
  requireAssetsAll(assetsSvgFiles)
}

/**
 * @description 拉起上传文件
 * @author Jinx
 * @date 2021-08-13 15:05:50
 * @export
 * @param {commitT} cb
 * @param {string} [accept]
 * @param {boolean} [multiple]
 */
export function getFile(cb: commitT, accept?: string, multiple?: boolean): void {
  const oInput = document.createElement('input')
  oInput.type = 'file'
  oInput.multiple = multiple ? true : false
  if(accept) {
    oInput.accept = accept
  }
  oInput.click()
  oInput.onchange = function (event) {
    const __target = event.target as any
    cb(__target.files)
  }
}

export const /**
 * @description 防抖函数
 * @author Jinx
 * @date 2021-08-17 15:16:10
 * @param {commitT} cb
 */
debounceClick = debounce(function(cb: commitT): void {
  // 防抖
  cb()
}, 500)


/**
 * @description 标题
 * @author Jinx
 * @date 2021-08-17 15:17:08
 * @export
 * @param {string} val
 * @returns {*}  {string}
 */
export function typeWatch(val?: string): string {
  switch (val) {
    case 'add':
      return '新增'
    case 'edit':
      return '编辑'
    default:
      return ''
  }
}


/**
 * @description 时间过滤
 * @author Jinx
 * @date 2021-08-18 14:31:15
 * @export
 * @param {(string | number)} time
 * @param {string} cFormat
 * @returns {*}  {(string | null)}
 */
export function parseTime(time: string | number, cFormat?: string): string | null {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string')) {
      if ((/^[0-9]+$/.test(time))) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj: any = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * @description 判断设备
 * @author Jinx
 * @date 2021-09-14 10:47:56
 * @export
 * @returns {*}  {(RegExpMatchArray | null)}
 */
export function equipmentType(): RegExpMatchArray | null {
  return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
}


/**
 * @description 地址栏数据转对象
 * @author Jinx
 * @date 2021-10-13 16:30:35
 * @export
 * @param {string} searchUrl
 * @returns {*}  {*}
 */
export function formatSearch(searchUrl: string): any {
  const urlArr = searchUrl.substr(1).split('&')
  const obj: any = {}
  urlArr.forEach((item: string) => {
    const arr = item.split('=')
    obj[arr[0]] = arr[1]
  })
  return obj
}

export function base64ToFile(base64Data:never | any, fileName: string): File {
  const arr = base64Data.split(',')
  const fileType = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let l = bstr.length
  const u8Arr = new Uint8Array(l)

  while (l--) {
    u8Arr[l] = bstr.charCodeAt(l);
  }
  return new File([u8Arr], fileName, {
      type: fileType
  })
}

/**
 * @description arrayBufferToBase64
 * @author Jinx
 * @date 2021-10-28 12:34:52
 * @export
 * @param {ArrayBuffer} reponseData
 * @returns {*}  {string}
 */
export function arrayBufferToBase64(reponseData: ArrayBuffer): string {
  const bytes = new Uint8Array(reponseData)
  let data = '';
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    data += String.fromCharCode(bytes[i]);
  }
  return 'data:image/png;base64,' + window.btoa(data)
}

/**
 * @description 对象转字符串
 * @author Jinx
 * @date 2021-10-29 14:39:57
 * @export
 * @param {{[prop: string]: any}} object
 * @returns {*}  {string}
 */
export function objectToGetParams(object: {[prop: string]: any}): string {
  return (
    '?' +
    Object.keys(object)
      .filter(key => !!object[key])
      .map(key => `${key}=${encodeURIComponent(object[key])}`)
      .join('&')
  );
}

export function sharer(type: number): void {
  switch (type) {
    case 1:
      window.open('http://www.facebook.com/sharer.php?u=' + encodeURIComponent('http://www.taosseract.com') + '&t=' + encodeURIComponent(document.title), '__blank')
      break
    case 3:
      window.open(
        'https://api.whatsapp.com/send' +
        objectToGetParams({
         text: 'http://www.taosseract.com'
        }),
        '__blank'
       );
      break
    case 2:
      ElNotification({
        type: 'info',
        message: '请自行复制网站链接，进行分享'
      })
      break
    default:
  }
}
