import service, { Response, ResponseData } from "@/api/request"
import { RequestParams } from "@/models/type"
import store from "@/store"
import { UserStoreRow } from "@/store/modules/user"

const api = {
  home_content(params: {monthId: number}): Promise<Response> {
    return service({
      url: '/home/home_content',
      method: 'get',
      params
    })
  },
  /**
   * @description 道驿空间根据月份id获取课程和活动
   * @author Jinx
   * @date 2021-09-23 18:30:02
   * @param {{monthId: number}} params
   * @returns {*}  {Promise<Response>}
   */
  subject_activity(params: {monthId: number}): Promise<Response> {
    const useStore = store.state as any
    const userStore = useStore.user as UserStoreRow
    return service({
      url: `/home/subject_activity/${userStore.userinfo.memberLevelId || 4}`,
      method: 'get',
      params
    })
  },
  getGoodWorkList(params: GoodWorkListParams): Promise<Response> {
    return service({
      url: '/home/getGoodWorkList',
      method: 'get',
      params
    })
  },
  homeRender(params: HomeRenderParams): Promise<Response> {
    return service({
      url: '/home/render',
      method: 'post',
      params
    })
  },
  /**
   * @description 检查输入账号是否已被注册
   * @author Jinx
   * @date 2021-10-11 17:45:44
   * @param {{ username: string }} params
   * @returns {*}  {Promise<Response>}
   */
  checkAccount(params: { username: string, source: string }): Promise<Response> {
    return service({
      url: '/home/checkAccount',
      method: 'post',
      params
    })
  },
  bindPhoneOrEmail(params: BindPhoneOrEmailParams): Promise<Response> {
    return service({
      url: '/home/bindPhoneOrEmail',
      method: 'post',
      params
    })
  },
  /**
   * @description 判断验证码是否正确
   * @author Jinx
   * @date 2021-10-11 17:46:47
   * @param {({code: string | number})} params
   * @returns {*}  {Promise<Response>}
   */
  verifyCode(params: {username: string, code: string | number}): Promise<Response> {
    return service({
      url: '/home/verifyCode',
      method: 'get',
      params
    })
  },
  /**
   * @description 获取导师列表
   * @author Jinx
   * @date 2021-10-15 17:45:40
   * @returns {*}  {Promise<Response>}
   */
  getTutorList(): Promise<Response> {
    return service({
      url: '/home/tutor/getTutorList',
      method: 'get'
    })
  },
  /**
   * @description 根据id获取课程详情
   * @author Jinx
   * @date 2021-10-17 16:01:26
   * @param {number} id
   * @returns {*}  {Promise<Response>}
   */
  getSubjectById(id: number): Promise<Response> {
    const useStore = store.state as any
    const userStore = useStore.user as UserStoreRow
    return service({
      url: `/home/getSubjectById/${id}/${userStore.userinfo.memberLevelId || 4}`,
      method: 'get'
    })
  },
  /**
   * @description 获取活动列表
   * @author Jinx
   * @date 2021-10-17 20:54:41
   * @returns {*}  {Promise<Response>}
   */
  getActivityAllList(params: RequestParams): Promise<Response> {
    const useStore = store.state as any
    const userStore = useStore.user as UserStoreRow
    return service({
      url: `/home/getActivityAllList/${userStore.userinfo.memberLevelId || 4}`,
      method: 'get',
      params
    })
  },
  /**
   * @description 根据id 获取活动详情
   * @author Jinx
   * @date 2021-10-18 09:20:28
   * @param {number} id
   * @returns {*}  {Promise<Response>}
   */
  getActivityById(id: number): Promise<Response> {
    const useStore = store.state as any
    const userStore = useStore.user as UserStoreRow
    return service({
      url: `/home/getActivityById/${id}/${userStore.userinfo.memberLevelId || 4}`,
      method: 'get'
    })
  },
  /**
   * @description 获取展览列表
   * @author Jinx
   * @date 2021-10-18 09:21:48
   * @param {RequestParams} params
   * @returns {*}  {Promise<Response>}
   */
  getShowAllList(params: RequestParams): Promise<Response> {
    return service({
      url: `/home/getShowAllList`,
      method: 'get',
      params
    })
  },
  /**
   * @description 展览详情
   * @author Jinx
   * @date 2021-10-18 10:24:51
   * @param {number} id
   * @returns {*}  {Promise<Response>}
   */
  getShowById(id: number): Promise<Response> {
    return service({
      url: `/home/getShowById/${id}`,
      method: 'get'
    })
  },

  /**
   * @description 作品详情
   * @author Jinx
   * @date 2021-10-18 11:11:17
   * @param {number} id
   * @returns {*}  {Promise<Response>}
   */
  getWorkById(id: number): Promise<Response> {
    return service({
      url: `/home/getWorkById/${id}`,
      method: 'get'
    })
  },
}

export default api

declare interface GoodWorkListParams extends RequestParams {
  type: number | string
}

export interface homeDate {
  homeImg: Array<homeImgRow>
  homeSubjectList: Array<homeSubjectListRow>
}

export interface SubjectActivityData {
  articleAndActivityResultList: Array<ArticleAndActivityResultListRow>
  finallySubjectResultList: Array<FinallySubjectResultListRow>
}


export interface homeImgRow extends ResponseData {
  imgPath: string
  status: number
  title: string
  type: number
  url?: string
}

export interface homeSubjectListRow extends ResponseData {
  fullName: string
  imgPath: string
  monthId: number
  title: string
}

export interface ArticleAndActivityResultListRow extends ResponseData {
  type: string,
  articleId: number
  articleImgUrl: string
  articleIntroduce: string
  articleTitle: string
  smsActivityList: Array<SmsActivityListRow>
}

export interface FinallySubjectResultListRow extends ResponseData {
  type: string,
  monthId: string
  subjectTypeName: string
  subjectValue: string,
  smsSubjectAndChapterDtoList: Array<SmsSubjectAndChapterDtoListRow>
}

export interface SmsActivityListRow extends ResponseData {
  activityTime: Date | string
  coverImg: number
  identity: string
  introduce: string
  mainImg: string
  otherImg: string
  peopleNumber: number
  point: string
  position: string
  price: number
  signUpEndTime: Date | string
  signUpStartTime: Date | string
  title: string
}

export interface SmsSubjectAndChapterDtoListRow extends ResponseData {
  coverImg: string
  identity: string
  introduce: string
  mainImg: string
  monthId: number
  otherImg: string
  peopleNumber: number
  price: number
  signUpEndTime: Date | string
  signUpStartTime: Date | string
  smsSubjectChapterDtos: Array<SmsSubjectChapterDtosRow>
  status: string
  subjectEndTime: Date | string
  subjectStartTime: Date | string
  title: string
  tutorEmail: string
  tutorId: number
  tutorIdentity: string
  tutorImgUrl: string
  tutorIntroduce: string
  tutorPhone: string
  tutorSubjectType: string
  tutorTitle: string
  typeId: number
  subjectTypeName: string
  type: string
}


export interface SmsSubjectChapterDtosRow extends ResponseData {
  integral: string
  subjectId: number
  time: Date | string
  title: string
}


export interface HomeRenderParams {
  source: string // google facebook
  url: string
}

export interface BindPhoneOrEmailParams {
  account: string
  avatar: string
  confirmPassword: string
  nickname: string
  password: string
  source: string
  username: string
  uuid: string
}
